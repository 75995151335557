// *******~ Import ~******** //
// React
// Assets
import { lazy, createContext, useState, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// Components
import GoTop from "./common/gototop/gototop";
import ScrollToTop from "./common/scrolltop/scrolltop";
import Routing from "./router/router";
import Header from "./common/header/header";
import Sitemap from "../src/pages/seo/sitemap";
import FeedBack from "./common/feedBack/feedBack";
// import Footer from "./common/footer/footer";
import { useEffect } from "react";
import { OrderFlow } from "./App";
const Footer = lazy(() => import("./common/footer/footer"));
// CSS
// Images
// Icons
// *******~ Import ~******** //
const Main = (params) => {
  const { footerLoading, setFooterLoading } = OrderFlow();
  return (
    <>
      <Router basename={"/"}>
        <Routes>
          <Route path="/sitemap.xml" element={<Sitemap />} />
          <Route
            path="/*"
            element={
              <>
                <GoTop />
                <ScrollToTop />
                <Header />
                <Routing stripe={params.stripe} />
                {footerLoading ? (
                  <>
                    <Footer />
                  </>
                ) : null}
                {/* <FeedBack /> */}
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
};
export default Main;
