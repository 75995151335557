import React, { useEffect, useState } from "react";
import { getSitemapApi } from "../../api/seo/sitemap";

const Sitemap = () => {
  const [sitemap, setSitemap] = useState(null); // Initialize sitemap state to null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await getSitemapApi();
        if (apiResponse?.data?.status === false) {
          // Handle error condition
          console.error(
            "Error fetching sitemap data:",
            apiResponse.data.message
          );
        } else {
          // Handle success condition
          const sitemapContent = `<?xml version="1.0" encoding="UTF-8"?>
            <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
              ${apiResponse.data.message
                .map(
                  (url) =>
                    `<url>
                      <loc>${url.loc}</loc>
                      <lastmod>${url.lastmod}</lastmod>
                      <priority>${url.priority}</priority>
                    </url>`
                )
                .join("\n")}
            </urlset>`;
          setSitemap(sitemapContent); // Update sitemap state with the fetched data
        }
      } catch (error) {
        console.error("Error fetching sitemap data:", error);
      }
    };

    fetchData(); // Call fetchData when the component mounts
  }, []); // Empty dependency array to ensure this effect runs only once

  return (
    <div>
      <pre>{sitemap}</pre> {/* Display sitemap content */}
    </div>
  );
};

export default Sitemap;
